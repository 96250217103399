import React from "react"
import BaseBlockContent from "@sanity/block-content-to-react"

import styles from "./blockcredits.module.css"

const serializers = {
  types: {
    block(props) {
      switch (props.node.style) {
        case "h1":
          return <h1>{props.children}</h1>

        case "h2":
          return <h2>{props.children}</h2>

        case "h3":
          return <h3>{props.children}</h3>

        case "h4":
          return <h4 className={styles.headFour}>{props.children}</h4>

        case "h5":
          return <h5>{props.children}</h5>

        case "blockquote":
          return <blockquote>{props.children}</blockquote>

        default:
          return <p className={styles.paragraph}>{props.children}</p>
      }
    },
  },
}

const BlockCredits = ({ blocks }) => (
  <BaseBlockContent blocks={blocks} serializers={serializers} />
)

export default BlockCredits
