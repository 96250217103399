import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlockContent from "../components/blockcontent"
import BlockDetails from "../components/blockdetails"
import BlockCredits from "../components/blockcredits"
import { featured } from "../utils/image"
import t from "../utils/translate"

import styles from "./project.module.css"

export const query = graphql`
  query($id: String!) {
    sanityProject(id: { eq: $id }) {
      id
      name {
        ...Locales
      }
      slug {
        current
      }
      image {
        asset {
          url
        }
      }
      seo {
        ...AllSeo
      }
      _rawLocationdetails
      _rawProjectInfo
      _rawContent
      _rawCredits
    }
  }
`

const ProjectPage = ({ data, pageContext: { locale, strings } }) => {
  const project = data.sanityProject

  const [showDiv, setShow] = useState(false)
  return (
    <Layout locale={locale}>
      <Seo locale={locale} seofield={project.seo}></Seo>
      <div className={styles.projectContainer}>
        <h1 className={styles.h1}>{t(project.name, locale)}</h1>
        <div className={styles.gapContainer}>
          <img
            className={styles.gapTwo}
            srcSet={`${featured(project.image, 400)} 360w, ${featured(
              project.image,
              632
            )} 592w, ${featured(project.image, 807)} 767w, ${featured(
              project.image,
              1022
            )} 992w`}
            src={featured(project.image, 1600)}
            alt={project.name}
          />
          <BlockDetails
            className={styles.gapFour}
            projectDetails={project._rawLocationdetails}
            strings={strings}
            locale={locale}
          ></BlockDetails>
        </div>
      </div>
      <div className={styles.infoContainer}>
        <h3
          className={showDiv ? styles.h3 : styles.h3less}
          onClick={() => setShow(!showDiv)}
        >
          {t(strings.projectinfo, locale)}
        </h3>
      </div>
      <div className={showDiv ? styles.hideShow : styles.showHide}>
        {t(project._rawProjectInfo, locale) && (
          <BlockContent
            blocks={t(project._rawProjectInfo, locale)}
          ></BlockContent>
        )}
      </div>
      {t(project._rawContent, locale) && (
        <BlockContent blocks={t(project._rawContent, locale)}></BlockContent>
      )}
      {project._rawCredits && (
        <div>
          <h2 className={styles.creditsTitle}>{t(strings.credits, locale)}</h2>
          <div className={styles.creditsContainer}>
            {t(project._rawCredits.creditColI, locale) && (
              <div className={styles.credits}>
                <BlockCredits
                  blocks={t(project._rawCredits.creditColI, locale)}
                ></BlockCredits>
              </div>
            )}
            {t(project._rawCredits.creditColII, locale) && (
              <div className={styles.creditsColII}>
                <BlockCredits
                  blocks={t(project._rawCredits.creditColII, locale)}
                ></BlockCredits>
              </div>
            )}
            {t(project._rawCredits.creditColIII, locale) && (
              <div className={styles.creditsColII}>
                <BlockCredits
                  blocks={t(project._rawCredits.creditColIII, locale)}
                ></BlockCredits>
              </div>
            )}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default ProjectPage
