import React from "react"

import t from "../utils/translate"

import styles from "./blockdetails.module.css"

const BlockDetails = ({projectDetails, locale, strings}) => (
  <div className={styles.detailsContainer}>
    {
      t(projectDetails.location, locale) && (
        <div>
          <h3>{t(strings.location, locale)}</h3>
          <h4>{t(projectDetails.location, locale)}</h4>
        </div>
      )
    }
    {
      t(projectDetails.city, locale) && (
        <div>
          <h3>{t(strings.city, locale)}</h3>
          <h4>{t(projectDetails.city, locale)}</h4>
        </div>
      )
    }
    {
      t(projectDetails.year, locale) && (
        <div>
          <h3>{t(strings.year, locale)}</h3>
          <h4>{t(projectDetails.year, locale)}</h4>
        </div>
      )
    }
    {
      t(projectDetails.status, locale) && (
        <div>
          <h3>{t(strings.status, locale)}</h3>
          <h4>{t(projectDetails.status, locale)}</h4>
        </div>
      )
    }
    {
      t(projectDetails.surface, locale) && (
        <div>
          <h3>{t(strings.surface, locale)}</h3>
          <h4>{t(projectDetails.surface, locale)}</h4>
        </div>
      )
    }
    {
      t(projectDetails.client, locale) && (
        <div>
          <h3>{t(strings.client, locale)}</h3>
          <h4>{t(projectDetails.client, locale)}</h4>
        </div>
      )
    }
    {
      t(projectDetails.program, locale) && (
        <div>
          <h3>{t(strings.program, locale)}</h3>
          <h4>{t(projectDetails.program, locale)}</h4>
        </div>
      )
    }
    {
      t(projectDetails.themes, locale) && (
        <div>
          <h3>{t(strings.themes, locale)}</h3>
          <h4>{t(projectDetails.themes, locale)}</h4>
        </div>
      )
    }
  </div>
)

export default BlockDetails
